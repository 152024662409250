import { NgModule } from '@angular/core';

import { TablerIconsModule } from 'angular-tabler-icons';
// import {
//   IconFileMinus,
//   IconPhotoHeart,
//   IconUser,
//   IconMenu2,
//   IconSearch,
// } from 'angular-tabler-icons/icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

// const icons = {
//   IconUser,
//   IconMenu2,
//   IconFileMinus,
//   IconPhotoHeart,
//   IconSearch,
// };

@NgModule({
  imports: [TablerIconsModule.pick(TablerIcons)],
  exports: [TablerIconsModule],
})
export class IconsModule {}
